@font-face {
    font-family: 'Greycliff';
    src: url('../fonts/greycliff-webfont.eot');
    src: url('../fonts/greycliff-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/greycliff-webfont.woff2') format('woff2'),
         url('../fonts/greycliff-webfont.woff') format('woff'),
         url('../fonts/greycliff-webfont.ttf') format('truetype'),
         url('../fonts/greycliff-webfont.svg#greycliff') format('svg');
    font-weight: normal;
    font-style: normal;
	text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Greycliff demibold';
    src: url('../fonts/greycliff-demibold-webfont.eot');
    src: url('../fonts/greycliff-demibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/greycliff-demibold-webfont.woff2') format('woff2'),
         url('../fonts/greycliff-demibold-webfont.woff') format('woff'),
         url('../fonts/greycliff-demibold-webfont.ttf') format('truetype'),
         url('../fonts/greycliff-demibold-webfont.svg#greycliff-demibold') format('svg');
    font-weight: normal;
    font-style: normal;
	text-rendering: optimizeLegibility;
}

:root {
	--scrollbar-color: #cdcfd3;
	--scrollbar-thumb-color: #adb1b5;
	--scrollbar-thumb-hover-color: #9da1a3;
	--input-placeholder-color: rgba(45,53,68,.6);
}

::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}
::-webkit-scrollbar-track {
	background: var(--scrollbar-color);
}
::-webkit-scrollbar-thumb {
	background: var(--scrollbar-thumb-color);
}
::-webkit-scrollbar-thumb:hover {
	background: var(--scrollbar-thumb-hover-color);
}

html, body, #root {
	margin: 0;
	padding: 0;
	background-color: #f5f6f7;
	color: #2d3544;
	font-family: 'Greycliff', Helvetica, Arial, Lucida, sans-serif;
	font-size: 14px;
	line-height: 20px;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	cursor: default;
}

*:not(input):not(textarea) {
	user-select: none;
}

h1, h2, h3, h4, h5, p, a {
	margin: 0;
	font-weight: 400;
}

h1 {
	font-family: 'Greycliff demibold', Helvetica, Arial, Lucida, sans-serif;
	font-size: 60px;
	line-height: 66px;
}

h2 {
	font-family: 'Greycliff demibold', Helvetica, Arial, Lucida, sans-serif;
	font-size: 36px;
	line-height: 42px;
}

h3 {
	font-size: 25px;
	line-height: 32px;
}

h4 {
	font-size: 18px;
	line-height: 26px;
}

li {
	padding-left: 10px;
}

sup {
	position: relative;
	top: 0.1em;
	font-size: .65em;
	line-height: 1px;
}

/* button */

.fs-button {
	font-family: 'Greycliff demibold', Helvetica, Arial, Lucida, sans-serif;
	font-size: 17px;
	line-height: 20px;
	text-align: center;
	background-color: #39C481;
	border: 0;
	border-radius: 50px;
	color: #fff;
	margin: 0;
	padding: 14px 65px 14px;
	transition: background-color .2s, opacity .2s;
	-webkit-appearance: none;
	outline: none;
	cursor: pointer;
}

.fs-button:hover:enabled {
	background-color: #3ed88b;
}

.fs-button:disabled {
	opacity: .5;
	cursor: auto;
}

/* input */

input, select {
	font-family: 'Greycliff', Helvetica, Arial, Lucida, sans-serif;
	box-sizing: border-box;
}

input:focus, select:focus, a:focus {
    outline: none;
}

.fs-object-title-input {
	border: none;
	background-color: rgba(0,0,0,0);
	color: #fff;
	font-family: 'Greycliff demibold', Helvetica, Arial, Lucida, sans-serif;
	font-size: 12px;
	text-align: center;
	width: 100%;
	height: 19px;
	margin: 0;
	padding: 0;
	-webkit-appearance: none;
}

.fs-input-text {
	border: none;
	border-bottom: 1px rgba(45,53,68,.4) solid;
	background-color: #fff;
	color: #2d3544;
	font-size: 18px;
	line-height: 18px;
	width: 340px;
	height: 40px;
	margin: 0 0 20px;
	padding: 0 20px 2px;
	-webkit-appearance: none;
}

.fs-input-text-small {
	border: none;
	border-bottom: 1px rgba(45,53,68,.4) solid;
	background-color: #fff;
	color: #2d3544;
	font-size: 14px;
	width: 260px;
	height: 38px;
	margin: 0 0 22px;
	padding: 1px 17px 1px;
	-webkit-appearance: none;
}

.fs-input-text:-webkit-autofill,
.fs-input-text:-webkit-autofill:hover,
.fs-input-text:-webkit-autofill:focus,
.fs-input-text:-webkit-autofill:active,
.fs-input-text-small:-webkit-autofill,
.fs-input-text-small:-webkit-autofill:hover,
.fs-input-text-small:-webkit-autofill:focus,
.fs-input-text-small:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
	-webkit-text-fill-color: #2d3544 !important;
	border-bottom: 1px rgba(45,53,68,.4) solid !important;
}

.fs-input-text::placeholder,
.fs-input-text-small::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: var(--input-placeholder-color);
	line-height: normal;
}

.fs-input-text:-ms-input-placeholder,
.fs-input-text-small:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: var(--input-placeholder-color);
	line-height: normal;
}

.fs-input-text::-ms-input-placeholder,
.fs-input-text-small::-ms-input-placeholder { /* Microsoft Edge */
	color: var(--input-placeholder-color);
	line-height: normal;
}

/* arrow-box */

.arrow_box {
	position: absolute;
	background: #384255;
	padding: 6px 10px;
	display: inline-block;
	box-sizing: border-box;
	box-shadow: 0 0 100px 0px rgba(0,0,0,.2);
}
.arrow_box:after {
	bottom: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(56, 66, 85, 0);
	border-bottom-color: #384255;
	border-width: 6px;
	margin-left: -6px;
}

/* account */

.accountElementContainer  {
	width: 100%; /* for IE compatibility */
	min-width: 980px; /*688px*/
	max-width: 1080px;
	margin: 0 auto;
	box-sizing: border-box;
	text-align: center;
	font-size: 18px;
	line-height: 26px;
}

.accountSubmitBtn {
	width: 100%;
	max-width: 270px;
	padding: 14px 25px 14px;
}

input.fs-input-text-signup,
select.fs-input-select-signup {
	border: none;
	border-radius: 0;
	background-color: #4B576B;
	font-size: 18px;
	width: 100%;
	height: 40px;
	box-sizing: border-box;
	margin-bottom: 20px;
	padding: 0 16px 1px;
	-webkit-appearance: none;
}

input.fs-input-text-signup-light,
select.fs-input-select-signup-light {
	background-color: #fff;
}

input.fs-input-text-signup {
	color: #fff !important;
}

select.fs-input-select-signup {
	color: rgba(255, 255, 255, 0.6) !important;
}

input.fs-input-text-signup-light {
	color: #2d3544 !important;
}

select.fs-input-select-signup-light {
	color: rgba(45,53,68,.5) !important;
}

input.fs-input-text-signup:-webkit-autofill,
input.fs-input-text-signup:-webkit-autofill:hover,
input.fs-input-text-signup:-webkit-autofill:focus,
input.fs-input-text-signup:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px #4B576B inset !important;
	-webkit-text-fill-color: #fff !important;
}

input.fs-input-text-signup-light:-webkit-autofill,
input.fs-input-text-signup-light:-webkit-autofill:hover,
input.fs-input-text-signup-light:-webkit-autofill:focus,
input.fs-input-text-signup-light:-webkit-autofill:active  {
	-webkit-box-shadow: 0 0 0 30px #fff inset !important;
	-webkit-text-fill-color: #2d3544 !important;
}

input.fs-input-text-signup::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: rgba(255, 255, 255, 0.6);
}

input.fs-input-text-signup-light::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: rgba(45,53,68,.5);
}

input.fs-input-text-signup:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: rgba(255, 255, 255, 0.6);
}

input.fs-input-text-signup-light:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: rgba(45,53,68,.5);
}

input.fs-input-text-signup::-ms-input-placeholder { /* Microsoft Edge */
	color: rgba(255, 255, 255, 0.6);
}

input.fs-input-text-signup-light::-ms-input-placeholder { /* Microsoft Edge */
	color: rgba(45,53,68,.5);
}

select.force-white {
	color: #fff !important
}

select.force-white-light {
	color: #2d3544 !important
}
